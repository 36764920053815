<template>
	<el-dialog
	    width="500px"
		title="赠送"
	    v-model="dialogVisible"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		:destroy-on-close="true"
		custom-class="dialogSell"
	>	
		<div style="width:450px;">
			<div class="title">作品名称</div>
			<div class="name">{{obj.pnName}}</div>
			<div class="title">TokenID</div>
			<div class="name">
				{{obj.pnTokenId}}
				&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="obj.pnType == '2'">版号:{{obj.pnNumb}}</span>
			</div>
			<div class="title">接收人</div>
			<el-select 
				v-model="form.urName" 
				style="width:100%;" 
				placeholder="请输入接收人姓名搜索" 
				remote
				filterable 
				reserve-keyword
				:remote-method="remoteMethod"
				:loading="loadingSearch"
				@change="selectBtn"
			>
				<el-option 
					popper-class="elSelect"
					v-for="(item,index) in rows" 
					:label="item.urName" 
					:key="item.urId" :value="item"
				>
					<div>
						<img class="elOptionImg" :src="item.urHead">{{item.urName}}
						<span v-if="item.urAddress != ''">({{item.urAddress}})</span>
					</div>
				</el-option>
			</el-select>
			<div class="title">手续费</div>
			<div class="name" style="font-weight:bold;"><font color=red>￥{{poundage}}</font></div>
			<div class="title">交易密码</div>
			<el-input type="password" v-model="form.urPayPass" placeholder="交易密码"></el-input>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="cancelBtn" :loading="loading">取消</el-button>
	        <el-button type="primary" :loading="loading" @click="confirmBtn">确认</el-button>
	      </span>
	    </template>
	</el-dialog>
	<el-dialog
	    width="500px"
		title="确认"
	    v-model="dialogVisible_0"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		:destroy-on-close="true"
		custom-class="dialogSell"
	>	
		<div style="width:450px;">
			<div class="name" style="font-weight:bold;line-height:25px;">
				确定将您的藏品 
				<font color=red>{{obj.pnName}}(TokenID:{{obj.pnTokenId}})</font>
				赠送给
				<font color=red>
					{{form.urName}}
					<span v-if="form.urAddress != ''">(ID:{{form.urAddress}})</span>
				</font>
				吗？本次交易一旦完成，将无法回退，请谨慎操作
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="cancelBtn_0" :loading="loading_0">取消</el-button>
	        <el-button type="primary" :loading="loading_0" @click="confirmBtn_0">确认</el-button>
	      </span>
	    </template>
	</el-dialog>
	<el-dialog
	    width="500px"
		title="请选择您要赠送的版号"
	    v-model="dialogVisible_1"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		:destroy-on-close="true"
		custom-class="dialogSell"
	>	
		<div style="width:450px;height:auto;overflow: hidden;">
			<div v-if="versionRows.length>0">
				<div
					class="list" 
					v-for="(item,index) in versionRows"
					:class="number==index?'cur':''" 
					@click="curBtn(index)"
				>
					{{item.pnNumb}}
				</div>
			</div>
			<div style="font-size:16px;font-weight:bold;" v-else>
				没有可赠送的作品。
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="cancelBtn_1" :loading="loading_1">取消</el-button>
	        <el-button type="primary" :loading="loading_1" @click="confirmBtn_1">确认</el-button>
	      </span>
	    </template>
	</el-dialog>
</template>
<script>
	import { Plus } from '@element-plus/icons-vue'
	import { ElLoading } from 'element-plus'
	export default {
		components:{ Plus },
		data() {
			return{
				loadingSearch:false,
				dialogVisible:false,
				dialogVisible_0:false,
				dialogVisible_1:false,
				loading:false,
				loading_0:false,
				loading_1:false,
				number:null,
				poundage:'',
				versionRows:[],
				obj:{},
				pnId:'',
				form:{
					urPayPass:'',
					urId:'',
					urName:'',
					urAddress:''
				}
			}
		},
		created(){
		},
		methods:{
			confirmBtn_1(){
				let that = this;
				if(that.pnId == ""){
					that.$message.error("请选择版本号!");
					return false;
				}
				that.dialogVisible = true;
			},
			confirmBtn_0(){
				let that = this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				that.loading_0 = true;
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'nft/gt/save',
					data:this.$qs.stringify({
						urPayPass: that.form.urPayPass,
						pnId: that.pnId,
						gtTo: that.form.urId
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("赠送成功!");
						that.$emit("onClose");
						that.form = {
							urPayPass:'',
							urId:'',
							urName:'',
							urAddress:''
						}
						that.dialogVisible = false
						that.dialogVisible_0 = false;
						that.dialogVisible_1 = false;
					}else{
						that.$message.error(msg);
					}
					that.loading_0 = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			confirmBtn(){
				let that = this;
				let urPayPass = that.form.urPayPass;
				if(urPayPass == ""){
					that.$message.error("请输入交易密码!");
					return false;
				}
				this.dialogVisible_0 = true;
			},
			selectBtn(val){
				this.form.urId = val.urId;
				this.form.urName = val.urName;
				this.form.urAddress = val.urAddress;
			},
			remoteMethod(query){
				if(query) {
					this.loadingSearch = true;
					this.getRecipient(query);
				}
			},
			getRecipient(search){
				let that =this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'user/ur/search',
					data:this.$qs.stringify({
						search: search,
					})
				})
				.then((res)=>{
					that.rows = res.data.dataRoot;
					this.loadingSearch = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getVersion(pnId){
				let that =this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'nft/pn/getGrantNumbs',
					data:this.$qs.stringify({
						pnParentId: pnId,
					})
				})
				.then((res)=>{
					that.versionRows = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getObject(pnId){
				let that =this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'nft/pn/getView',
					data:this.$qs.stringify({
						pnId: pnId,
					})
				})
				.then((res)=>{
					that.obj = res.data.data;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getService(pnId){
				let that =this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'nft/gt/getService',
					data:this.$qs.stringify({
						pnId: pnId,
					})
				})
				.then((res)=>{
					that.poundage = res.data.msg;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			curBtn(index){
				this.number = index;
				this.pnId = this.versionRows[index].pnId;
			},
			cancelBtn(){
				this.dialogVisible = false;
				this.form = {
					urPayPass:'',
					urId:'',
					urName:'',
					urAddress:''
				}
			},
			cancelBtn_0(){
				this.dialogVisible_0 = false;
			},
			cancelBtn_1(){
				this.dialogVisible_1 = false;
				this.form = {
					urPayPass:'',
					urId:'',
					urName:'',
					urAddress:''
				}
			}
		}
	};
</script>

<style scoped>
	.dialogSell .title{
		margin:15px 0px;
		font-weight: bold;
		font-size:16px;
	}
	.name{
		font-size:14px;
	}
	.list{
		width:32px;
		height:32px;
		line-height: 32px;
		color:#fff;
		text-align: center;
		background:rgb(89, 166, 243);
		margin-right:10px;
		margin-bottom:10px;
		border-radius: 5px;
		float: left;
	}
	.cur{
		background:rgb(45, 129, 226);
	}
	.elSelect .selected{
		height:60px;
		line-height: 60px;
		border-bottom:1px solid #e0e2e4;
	}
	.elSelect .selected:last-child{
		border-bottom:none;
	}
	.elOptionImg{
		width:30px;
		height:30px;
		vertical-align: middle;
		margin-right:10px;
		border-radius: 50%;
	}
</style>
