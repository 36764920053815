<template>
    <div class="ExploreCon" style="min-height:900px;">
		<div class="card-carousel--card" 
			:style="{'width':widthS + 'px','height':widthS + 'px'}" 
			v-for="(item,index) in rows" 
			:key="item.cnId" 
			@click="detailBtn(item.cnId)"
		>
			<div class="card-carousel-box">
				<el-image 
					:style="{'width':widthS + 'px','height':height + 'px'}" 
					:src="item.cnFeatured" 
					:fit="fit" 
					style="object-fit: cover;"
				/>
				<div class="circle">
					<img :src="item.cnLogo">
				</div>
			</div>
			<div class="card-carousel--card--footer" :style="{'height':height + 'px'}">
				<div class="title">
					{{item.cnName}}
				</div>
				<div class="title" style="color:rgb(32, 129, 226);font-size:14px;margin-top:5px;">
					{{item.urName}}
				</div>
				<div class="con">
					{{item.cnDes}}
				</div>
			</div>
			<div class="IconBox">
				<el-tooltip
					class="box-item"
					effect="dark"
					content="更多操作"
					placement="top"
				>
					<el-dropdown>
						<span class="Icon">
							<Icon icon="Menu"></Icon>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item @click="editBtn(item.cnId,index)">修改</el-dropdown-item>
								<el-dropdown-item @click="delBtn(item.cnId,index)">删除</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</el-tooltip>
			</div>
		</div>
	</div>
	<el-drawer
	    v-model="drawer"
	    :title="title"
	    size="50%"
		:destroy-on-close="true"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	>
	    <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
			<Edit ref="Edit" @onClose="onClose"></Edit>
		</div>
		<div class="drawerFooter">
			<el-button @click="drawer = false">取消</el-button>
			<el-button type="primary" @click="saveBtn" :loading="loading">保存</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import Icon from "@/components/Icon"
	import Edit from "../Edit/Edit"
	import { ElLoading } from 'element-plus'
	export default {
		props:['width'],
		components:{ ElLoading,Edit,Icon },
		data() {
			return{
				title:"新增作品信息",
				drawerHeight:'',
				loading:false,
				drawer:false,
				imageUrl:'',
				index:0,
				rows:[],
				widthS:'',
				height:"",
				currentPage: 1,
				total: 0,
				pageSize:24,
			}
		},
		watch:{
			width(newVal,oldVal){
				let number = newVal - 40;
				if(number <= 576){
					this.widthS = number;
					this.height = number/2
				}
				if(number > 576 && number < 1080){
					this.widthS = number/2 - 20;
					this.height = this.widthS/2
				}
				if(number > 1080 && number < 1400){
					this.widthS = number/3 - 40;
					this.height = this.widthS/2
				}
				if(number > 1400){
					this.widthS = number/4 - 60;
					this.height = this.widthS/2
				}
			},
		},
		created(){
			let that = this;
			that.initData();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
			let number = that.width - 40;
			if(number <= 700){
				this.widthS = number;
				this.height = number/2
			}
			if(number > 700 && number < 1200){
				this.widthS = number/2 - 40;
				this.height = this.widthS/2
			}
			if(number > 1200 && number < 1600){
				this.widthS = number/3 - 22;
				this.height = this.widthS/2
			}
			if(number > 1600){
				this.widthS = number/4 - 80;
				this.height = this.widthS/2
			}
		},
		methods:{
			detailBtn(cnId){
				let that = this;
				that.$router.push({
					path: "/Detail/Detail/"+cnId,
				})
			},
			saveBtn(){
				this.loading = true;
				this.$refs.Edit.saveBtn();
			},
			delBtn(cnId,index){
				let that = this;
				this.$confirm('确定要删除吗?删除将无法恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios({
						method:'post',
						url: 'nft/cn/delete',
						headers: {
							token:window.localStorage.getItem("token")
						},
						data:this.$qs.stringify({
							cnId: cnId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							let rows = that.rows;
							rows.splice(index,1);
							that.rows = rows;
							that.$message.success("删除成功！");
							that.$emit("getNumber");
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			editBtn(cnId,index){
				let that = this;
				that.title = "修改作品信息";
				that.drawer = true;
				that.index = index;
				that.$nextTick(()=>{
					that.$refs.Edit.getObject(cnId);
				})
			},
			initData(){
				let that = this;
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/cn/getMine',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId:window.localStorage.getItem("urId"),
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					this.total = res.data.totalCounts;
					this.rows = res.data.dataRoot;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
			onClose(index,form){
				if(index == "0"){
					this.drawer = false;
					if(form.cnId == ""){
						this.currentPage = 1;
						this.initData();
					}else{
						this.rows[this.index] = form;
					}
					this.$emit("getNumber");
				}else{
				}
				this.loading = false;
			}
		},
	};
</script>
<style scoped>
	.card-carousel--card--footer{
		box-sizing: border-box;
	}
	.card-carousel--card--footer .con{
		 overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp:4;
		-webkit-box-orient: vertical;
		margin-top:5px !important;
		margin-bottom:0px !important;
	}
</style>
