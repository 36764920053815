<template>
	<div>
		<el-select v-model="ssId" placeholder="请选择" @change="changeSsId">
			<el-option label="全部数据" value="" />
			<el-option label="待确认" value="nft_production_offer_0" />
			<el-option label="已超时" value="nft_production_offer_1" />
			<el-option label="已同意" value="nft_production_offer_2" />
			<el-option label="已拒绝" value="nft_production_offer_3" />
		</el-select>
		<el-input v-model="search" placeholder="作品名称/TokenID" style="width:300px;margin-left:10px;">
			<template #append>
				<span @click="searchBtn">
					<Icon icon="Search"></Icon>
				</span>
			</template>
		</el-input>
	</div>
	<div class="boxListCon">
		<el-table stripe :data="tableData" height="700">
			<el-table-column label="状态" width="150" align="center">
				<template #default="scope">
					<span style="font-weight:bold;font-size:16px;">
						{{scope.row.ssName}}
					</span>
				</template>
			</el-table-column>
			<el-table-column label="作品名称" align="center">
				<template #default="scope">
					<span style="font-weight:bold;font-size:16px;">{{scope.row.pnName}}</span>
				</template>
			</el-table-column>
			<el-table-column label="作品图" width="150" align="center">
				<template #default="scope">
					<img style="width:150px;height:150px;" :src="scope.row.pnImage ">
				</template>
			</el-table-column>
			<el-table-column label="TokenId" width="250" align="center">
				<template #default="scope">
					<span style="font-weight:bold;font-size:16px;">{{scope.row.pnTokenId}}</span>
				</template>
			</el-table-column>
			<el-table-column label="出价金额" width="200" align="center">
				<template #default="scope">
					<font style="font-size:20px;font-weight:bold;color:red;">
						￥{{scope.row.porPrice}}
					</font>
				</template>
			</el-table-column>
			<el-table-column label="出价时间" width="250" align="center">
				<template #default="scope">
					<span style="font-weight:bold;font-size:16px;">{{scope.row.porTime}}</span>
				</template>
			</el-table-column>
		  </el-table>
		  <div style="text-align:right;padding:8px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[24, 100, 200, 300]"
				:page-size="pageSize"
				:total="total"
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import Icon from "@/components/Icon"
	import Edit from "../Edit/Edit"
	import { ElLoading } from 'element-plus'
	export default {
		props:['width'],
		components:{ ElLoading,Edit,Icon },
		data() {
			return{
				loading:false,
				height:'',
				rows:[],
				currentPage: 1,
				total: 0,
				pageSize:24,
				tableData:[],
				search:'',
				ssId:'nft_production_offer_0',
			}
		},
		created(){
			let that = this;
			that.initData();
		},
		methods:{
			changeSsId(val){
				this.ssId = val;
				this.currentPage = 1;
				this.initData();
			},
			searchBtn(){
				let that = this;
				that.currentPage = 1;
				that.initData();
			},
			initData(){
				let that = this;
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/por/getMine',
					headers: {
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						search: that.search,
						ssId: that.ssId,
						page: that.currentPage,
						limit: that.pageSize,
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
					})
					that.tableData = rows;
					that.total = res.data.totalCounts;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
			
			handleSizeChange(val){
				this.pageSize = val;
				this.currentPage = 1;
				this.initData();
			},
			handleCurrentChange(val){
				this.currentPage = val;
				this.initData();
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 440;
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	};
</script>
<style scoped>
	.boxListCon{
		margin-top:20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user{
		display: flex;
		justify-content: center;
		line-height: 50px;
	}
	.user .text{
		align-self: center;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:50px;
		height:50px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
	.el-table{
		background:rgb(243, 243, 243);
	}
</style>