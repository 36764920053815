<template>
    <div class="ExploreCon">
		<div>
			<el-select v-model="type" placeholder="请选择" @change="changeType">
				<el-option label="所有" value="0" />
				<el-option label="出售的" value="1" />
				<el-option label="买入的" value="2" />
			</el-select>
			<el-select v-model="ssId" placeholder="请选择" style="margin-left:10px;" @change="changeSsId">
				<el-option  key="" label="全部" value="" />
				<template v-for="item in rows">
					<el-option v-if="item.ssId != 'nft_order_3'" :key="item.ssId" :label="item.ssName" :value="item.ssId" />
				</template>
			</el-select>
			<el-input v-model="search" placeholder="订单号/作品名称/TokenID" style="width:300px;margin-left:10px;">
				<template #append>
					<span @click="searchBtn">
						<Icon icon="Search"></Icon>
					</span>
				</template>
			</el-input>
		</div>
		<div class="boxListCon">
			<el-table stripe :data="tableData" height="700">
				<el-table-column label="订单类型" width="150" align="center">
					<template #default="scope">
						<span style="font-weight:bold;font-size:16px;" v-if="scope.row.type=='0'">
							购买
						</span>
						<span style="font-weight:bold;font-size:16px;" v-else>
							出售
						</span>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="150" align="center">
					<template #default="scope">
						<span style="font-weight:bold;font-size:16px;">
							{{scope.row.ssName}}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="作品名称" width="250" align="center">
					<template #default="scope">
						<span style="font-weight:bold;font-size:16px;">{{scope.row.pnName}}</span>
					</template>
				</el-table-column>
				<el-table-column label="作品图" width="150" align="center">
					<template #default="scope">
						<img style="width:150px;height:150px;" :src="scope.row.pnImage ">
					</template>
				</el-table-column>
				<el-table-column label="作品价格" width="250" align="center">
					<template #default="scope">
						<font style="font-size:20px;font-weight:bold;color:red;">
							￥{{scope.row.orPrice}}
						</font>
					</template>
				</el-table-column>
				<el-table-column label="TokenId" width="250" align="center">
					<template #default="scope">
						<span style="font-weight:bold;font-size:16px;">{{scope.row.pnTokenId}}</span>
					</template>
				</el-table-column>
				<el-table-column  label="订单编号" width="250" align="center">
					<template #default="scope">
						<div class="cart">
							{{scope.row.orNumb}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="订单金额" width="200" align="center">
					<template #default="scope">
						<font style="font-size:20px;font-weight:bold;color:red;">
							￥{{scope.row.orAmt}}
						</font>
					</template>
				</el-table-column>
				<el-table-column label="下单时间" width="250" align="center">
					<template #default="scope">
						<span style="font-weight:bold;font-size:16px;">{{scope.row.orTime}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="250" align="center" fixed="right">
					<template #default="scope">
						<span v-if="scope.row.ssId=='nft_order_0' && scope.row.type == '0'">
							<!-- <el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="buyBtn(scope.row)"
							>
								支付
							</el-button> -->
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="delBtn(scope.row)"
							>
								删除
							</el-button>
						</span>
						<span v-if="scope.row.ssId=='nft_order_3' && scope.row.type == '0'">
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="delBtn(scope.row)"
							>
								删除
							</el-button>
						</span>
						<span v-if="scope.row.ssId=='nft_order_4' && scope.row.type == '0'">
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="delBtn(scope.row)"
							>
								删除
							</el-button>
						</span>
						<span v-if="scope.row.ssId=='nft_order_5' && scope.row.type == '1'">
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="goodsAddr(scope.row)"
							>
								收货地址
							</el-button>
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="consignmentBtn(scope.row)"
							>
								发货
							</el-button>
						</span>
						<span v-if="scope.row.ssId=='nft_order_6' && scope.row.type == '0'">
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="confirmBtn(scope.row)"
							>
								确认收货
							</el-button>
							<el-button
								style="color:#fff;height:30px;" 
								type="primary"
								@click="viewBtn(scope.row)"
							>
								查看物流
							</el-button>
						</span>
					</template>
				</el-table-column>
			  </el-table>
			  <div style="text-align:right;padding:8px;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[24, 100, 200, 300]"
					:page-size="pageSize"
					:total="total"
					layout="total, sizes, prev, pager, next, jumper"
				>
				</el-pagination>
			</div>
		</div>
    </div>
	<el-dialog
	    v-model="dialogVisible"
	    title="提示"
	    width="30%"
	    :before-close="handleClose"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	  >
	    <div style="font-size: 16px;font-weight: bold;">收件人:&nbsp;&nbsp;{{obj.orPerson}}</div>
		<div style="font-size: 16px;font-weight: bold;margin-top:10px;">联系电话:&nbsp;&nbsp;{{obj.orMobile}}</div>
		<div style="font-size: 16px;font-weight: bold;margin-top:10px;">联系地址:&nbsp;&nbsp;{{obj.orAddress}}</div>
	    <template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">知道了</el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog
	    v-model="dialogVisible_0"
	    title="提示"
	    width="30%"
	    :before-close="handleClose"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		:destroy-on-close="true"
	  >
		<div style="font-size: 16px;font-weight: bold;margin-bottom:10px;">快递公司</div>
	    <el-select filterable v-model="esName" class="m-2" placeholder="请选择" style="width:100%;" @change="selectBtn">
	        <el-option
	          v-for="item in options"
	          :key="item.esId"
	          :label="item.esName"
	          :value="item"
	        />
	      </el-select>
		  <div style="font-size: 16px;font-weight: bold;margin-top:10px;margin-bottom:10px;">运单号</div>
		  <el-input v-model="orTrans" placeholder="请填写运单号" />
	    <template #footer>
			<span class="dialog-footer">
				<el-button @click="cancelShipments">取消</el-button>
				<el-button type="primary" :loading="loading" @click="shipmentsBtn">发货</el-button>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog
	    v-model="dialogVisible_1"
	    title="提示"
	    width="30%"
	    :before-close="handleClose"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	  >
	    <div style="font-size: 16px;font-weight: bold;">物流公司:&nbsp;&nbsp;{{obj.esName}}</div>
		<div style="font-size: 16px;font-weight: bold;margin-top:10px;">运单号:&nbsp;&nbsp;{{obj.orTrans}}</div>
	    <template #footer>
			<span class="dialog-footer">
				<el-button @click="dialogVisible_1 = false">取消</el-button>
				<el-button type="primary" @click="openUrl">跳转至物流查询页面</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script>
	import Icon from "@/components/Icon"
	import Edit from "../Edit/Edit"
	import { ElLoading } from 'element-plus'
	import Image from "../../../../assets/img/Love.png"
	import loveCur from "../../../../assets/img/loveCur.png"
	export default {
		props:['width'],
		inject: ["reload"],
		components:{ ElLoading,Edit,Icon },
		data() {
			return{
				title:"新增作品信息",
				drawerHeight:'',
				dialogVisible:false,
				dialogVisible_0:false,
				dialogVisible_1:false,
				loading:false,
				drawer:false,
				height:'',
				rows:[],
				Image: Image,
				loveCur:loveCur,
				currentPage: 1,
				total: 0,
				pageSize:24,
				tableData:[],
				search:'',
				type:'0',
				ssId:'',
				obj:{},
				options:[],
				orTrans:'',
				esId:'',
				esName:''
			}
		},
		created(){
			let that = this;
			that.initData();
			that.getListState();
			that.setHeight();
			that.getListCourier();
		},
		methods:{
			delBtn(rows){
				let that = this;
				that.$confirm('确认收货吗？确认之后将无法修改!', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					this.$axios({
						method:'post',
						url:'nft/or/delete',
						headers: {
							token:window.localStorage.getItem("token")
						},
						data:this.$qs.stringify({
							orId: rows.orId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg == 2){
							that.$message.success("删除成功!");
							that.initData();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			openUrl(){//查询物流
				this.dialogVisible_1 = false;
				window.open(this.obj.url);
			},
			viewBtn(rows){//查看物流
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/or/getRefer',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						orId: rows.orId
					})
				})
				.then((res)=>{
					rows.url = res.data.msg;
					that.obj = rows;
					that.dialogVisible_1 = true;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			confirmBtn(rows){//确认收货
				let that = this;
				that.$confirm('确认收货吗？确认之后将无法修改!', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					this.$axios({
						method:'post',
						url:'nft/or/receive',
						headers: {
							token:window.localStorage.getItem("token")
						},
						data:this.$qs.stringify({
							orId: rows.orId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg == 2){
							that.$message.success("确认收货成功!");
							that.initData();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			shipmentsBtn(){//发货
				let that = this;
				let esId = that.esId;
				let orTrans = that.orTrans;
				if(esId == ""){
					that.$message.error("请选择快递公司!");
					return false;
				}
				if(orTrans == ""){
					that.$message.error("请快递单号!");
					return false;
				}
				that.loading = true;
				this.$axios({
					method:'post',
					url:'nft/or/send',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						orId: that.obj.orId,
						esId: that.esId,
						esName: that.esName,
						orTrans: that.orTrans
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.orTrans = '';
						that.esId = '';
						that.$message.success("发货成功!");
						that.dialogVisible_0 = false;
						that.initData();
					}else{
						that.$message.error(msg);
					}
					that.loading = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			selectBtn(val){
				this.esId = val.esId;
				this.esName = val.esName;
			},
			cancelShipments(){
				let that = this;
				that.orTrans = '';
				that.esId = '';
				that.dialogVisible_0 = false;
			},
			consignmentBtn(rows){
				this.obj = rows;
				this.dialogVisible_0 = true;
			},
			goodsAddr(rows){
				this.obj = rows;
				this.dialogVisible = true;
			},
			changeType(val){
				this.type = val;
				this.currentPage = 1;
				this.initData();
			},
			changeSsId(val){
				this.ssId = val;
				this.currentPage = 1;
				this.initData();
			},
			searchBtn(){
				let that = this;
				that.currentPage = 1;
				that.initData();
			},
			initData(){
				let that = this;
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/or/getList',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						search: that.search,
						type: that.type,
						ssId: that.ssId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
					})
					that.tableData = rows;
					that.total = res.data.totalCounts;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListCourier(){
				let that = this;
				this.$axios({
					method:'post',
					url:'sys/es/getList',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						page: 1,
						limit: 1000
					})
				})
				.then((res)=>{
					that.options = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			handleSizeChange(val){
				this.pageSize = val;
				this.currentPage = 1;
				this.initData();
			},
			handleCurrentChange(val){
				this.currentPage = val;
				this.initData();
			},
			getListState(){
				let that = this;
				this.$axios({
					method:'post',
					url:'sys/ss/getList',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						ssKey:"nft_order",
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.rows = rows;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 440;
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	};
</script>
<style scoped>
	.boxListCon{
		margin-top:20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user{
		display: flex;
	}
	.user .text{
		align-self: center;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:30px;
		height:30px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
</style>
