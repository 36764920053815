<template>
    <div class="User">
		<div class="tabBox">
			<div class="tab" :class="type==0?'cur':''" @click="switchTab(0)">我的专辑({{countCol}})</div>
			<div class="tab" :class="type==1?'cur':''" @click="switchTab(1)">售卖中({{countSale}})</div>
			<div class="tab" :class="type==2?'cur':''" @click="switchTab(2)">藏品({{countBuy}})</div>
			<div class="tab" :class="type==3?'cur':''" @click="switchTab(3)">我创建的({{countCreate}})</div>
			<div class="tab" :class="type==4?'cur':''" @click="switchTab(4)">关注的({{countFavorite}})</div>
			<div class="tab" :class="type==5?'cur':''" @click="switchTab(5)">我的订单({{countOrder}})</div>
			<div class="tab" :class="type==6?'cur':''" @click="switchTab(6)">出价记录({{countOffer}})</div>
		</div>
		<div class="buttom">
			<el-button v-if="type==0" type="primary" @click="createBtn">创建</el-button>
		</div>
		<Album ref="Album" @getNumber="getNumber" :width="widthAlbum" :height="height" :drawerHeight="drawerHeight" v-if="type==0"></Album>
		<Sell ref="Sell" @getNumber="getNumber" :width="width" v-if="type==1"></Sell>
		<Collect ref="Collect" @getNumber="getNumber" :width="width" v-if="type==2"></Collect>
		<Create ref="Create" @getNumber="getNumber" :width="width" v-if="type==3"></Create>
		<Focus ref="Focus" @getNumber="getNumber" :width="width" v-if="type==4"></Focus>
		<Order ref="Order" @getNumber="getNumber" :width="width" v-if="type==5"></Order>
		<div v-if="type==6">
			<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			    <el-tab-pane label="买家出价" name="1">
					<BuyOffer ref="BuyOffer" @getNumber="getNumber" :width="width"></BuyOffer>
				</el-tab-pane>
			    <el-tab-pane label="我的出价" name="2">
					<MyOffer ref="MyOffer" @getNumber="getNumber" :width="width"></MyOffer>
				</el-tab-pane>
			</el-tabs>
		</div>
    </div>
</template>
<script>
	import Icon from "@/components/Icon"
	import Album from "./List/Album"//专辑
	import Sell from "./List/Sell"//售卖
	import Collect from "./List/Collect"//藏品
	import Create from "./List/Create"//创建
	import Focus from "./List/Focus"//关注
	import Order from "./List/Order"//订单
	import BuyOffer from "./List/BuyOffer"//买家出价
	import MyOffer from "./List/MyOffer"//我的出价
	export default {
		components:{ Album,Create,Sell,Collect,Focus,Order,BuyOffer,MyOffer,Icon },
		data() {
			return{
				countCol:"",
				countSale:"",
				countBuy:"",
				countCreate:"",
				countFavorite:"",
				countOrder:"",
				countOffer:'',
				type:0,
				activeName:'1',
				width:'',
				widthAlbum:'',
				height:'',
				drawerHeight:''
			}
		},
		created(){
			let that = this;
			let type = that.$route.query.type;
			if(type == 3){
				that.type = 3;
			}else if(type == 5){
				that.type = 5;
			}else{
				that.type = 0;
			}
			that.getNumber();
		},
		methods:{
			switchTab(index){
				this.type = index;
			},
			createBtn(){
				this.$refs.Album.title = "新增作品信息";
				this.$refs.Album.drawer = true;
			},
			getNumber(){
				let that = this;
				this.$axios({
					method:'post',
					url:'user/ur/getCount',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId:window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					that.countCol = res.data.countCol; 
					that.countSale = res.data.countSale;
					that.countBuy = res.data.countBuy; 
					that.countCreate = res.data.countCreate ;
					that.countFavorite = res.data.countFavorite ;
					that.countOrder = res.data.countOrder;
					that.countOffer = res.data.countOffer;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getScreenWidthHeight(){
				let that = this;
				let width = '';
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				let w = document.documentElement.clientWidth || document.body.clientWidth;
				that.drawerHeight = h - 147 + 'px';
				w = (w-17)*0.8;
				this.widthAlbum = w;
				if(w < 576){
					this.width = parseInt(w) - 40;
				}else if(w > 576 && w < 768){
					width = w/2;
					this.width = parseInt(width) - 50;
				}else if(w > 768 && w < 1080){
					width = w/3
					this.width = parseInt(width) - 50;
				}else if(w > 1080 && w < 1280){
					width = w/4
					this.width = parseInt(width) - 50;
				}else if(w >= 1280 && w < 1920){
					width = w/5
					this.width = parseInt(width) - 30;
				}else if(w >= 1920){
					width = w/6
					this.width = parseInt(width) - 30;
				}
			}
		},
		beforeMount(){
			this.getScreenWidthHeight();
		},
		mounted(){
			window.addEventListener('resize', this.getScreenWidthHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.getScreenWidthHeight, false)
		}
	};
</script>
<style scoped>
	.User{
		padding:20px;
		box-sizing: border-box;
	}
	.tab{
		display: inline-block;
		padding:0px 20px;
		color:#999;
	}
	.tab:first-child{
		padding-left:0px;
	}
	.buttom{
		margin-top:20px;
	}
	.cur{
		color:#000;
	}
</style>
